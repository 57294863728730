<template>
  <div class="main">
    <h1 class="h1">{{$t('Message')}}</h1>
    <tabs v-model="activeName">
      <tab-pane :label="$t('Unread')" name="0" >
        <message :activeName="activeName"/>
      </tab-pane>
      <tab-pane :label="$t('Read')" name="1">
        <message :activeName="activeName"/>
      </tab-pane>
<!--      <tab-pane :label="$t('Latest message')" name="news">-->
<!--        <message :activeName="activeName"/>-->
<!--      </tab-pane>-->
    </tabs>
  </div>
</template>
<script>
  import Tabs from '../../components/Tabs/Tabs';
  import TabPane from '../../components/Tabs/TabPane';
  import Message from './components/Message';

  export default {
    name: 'MessageList',
    data() {
      return {
        activeName: '0'
      };
    },
    mounted() {
      this.activeName = this.$route.query.type || '0';
    },
    components: {Tabs, TabPane, Message}
  };
</script>
