<template>
  <div class="message-list">
    <div class="filter cl" v-if="activeName !== 'news'">
      <div class="fr">
        <div class="fl label">{{$t('Order number')}}：</div>
        <el-input class="ipt" size="small" v-model.number="content" @change="getNoticeList(1)"></el-input>
      </div>
    </div>
    <div class="message-item" v-for="(item,index) of list" :key="item.messageId">
      <div class="hd cl">
        <div class="fr" v-if="activeName !== 'news'">{{$t('Date')}}：{{item.sendTime|formatDate('yyyy-MM-dd hh:mm:ss')}}</div>
        <div class="fr" v-else>{{$t('Date')}}：{{item.addDate|formatDate('yyyy-MM-dd hh:mm:ss')}}</div>
        <div class="title">
          <i class="icons i-letter" :class="{unread:item.isStatus==='unread'}"></i>
          <span><b>{{item.title}}</b></span>
        </div>
      </div>
      <div class="bd" v-html="item.content"></div>
      <div class="ft">
        <a v-if="activeName ==='news'" class="btn-gray" href="javascript:;" @click="deleteMessage(index,item.messageId)">{{$t('Delete')}}</a>
        <a v-else class="btn-gray" href="javascript:;" @click="deleteNoticeMessage(index,item)">{{$t('Delete')}}</a>
        <a v-if="activeName ==='news'" class="btn-primary" href="javascript:;" @click="topMessage(index,item.messageId)">{{$t('Top')}}</a>
        <a v-if="activeName === '0'" class="btn-primary" href="javascript:;" @click="isReadNoticeMessage(index,item)">{{$t('Read')}}</a>
      </div>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import Empty from '../../../components/Empty';
  import {getMessageList, deleteMessage, setMessage, getNoticeMessageList, updateNoticeMessage} from '../../../api/message';

  export default {
    name: 'Message',
    props: {activeName: Object},
    data() {
      return {
        list: [],
        totalPages: 1,
        isEmpty: false,
        content: ''
      };
    },
    components: {Empty},
    created() {
      console.log(this.activeName);
      if (this.activeName === 'news') {
        this.getList();
      } else {
        this.getNoticeList();
      }
    },
    methods: {
      // 获取消息列表
      getList(page = 1) {
        const data = {page, perPage: 10};
        getMessageList(data).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.setRead(list);
        });
      },
      getNoticeList(page = 1) {
        const data = {page, perPage: 10, isRead: this.activeName, content: this.content};
        getNoticeMessageList(data).then(res => {
          console.log(res);
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.setRead(list);
        });
      },
      isReadNoticeMessage(index, date) {
        var date1 = {
          id: date.id,
          isRead: 1
        };
        updateNoticeMessage(date1);
        this.list.splice(index, 1);
        if (!this.list.length) {
          this.getNoticeList();
        }
      },
      deleteNoticeMessage(index, date) {
        var date1 = {
          id: date.id,
          isDelete: 1
        };
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              updateNoticeMessage(date1).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.list.splice(index, 1);
                if (!this.list.length) {
                  this.getNoticeList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(() => {
        }).catch(() => {
        });
      },
      changePage(page) {
        if (this.activeName === 'news') {
          this.getList(page);
        } else {
          this.getNoticeList(page);
        }
      },
      // 删除消息
      deleteMessage(index, messageId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteMessage(messageId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.list.splice(index, 1);
                if (!this.list.length) {
                  this.getList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(() => {
        }).catch(() => {
        });
      },
      // 消息置顶
      topMessage(index, messageId) {
        setMessage(messageId).then(res => {
          this.list[index].isTop = true;
          this.listSort();
        });
      },
      // 置顶排序
      listSort() {
        this.list.sort((a, b) => {
          return b.isTop ? 0 : -1;
        });
      },
      // 批量设置已读
      setRead(list) {
        let reqList = list.filter(item => item.isStatus === 'unread');
        if (reqList.length) {
          reqList = reqList.map(item => {
            return setMessage(item.messageId, 'read');
          });
          Promise.news(reqList).then(res => {
          });
        }
      }
  }};
</script>
<style scoped lang="less">
  .message-list{margin-top:20px;}
  .message-item{
    margin-bottom:8px;padding:20px;background-color:#f4f4f4;border-radius:4px;margin-top:10px;
    .hd{padding-bottom:4px;border-bottom:1px dashed #e0e0e0;}
    .bd{margin-top:4px;overflow: auto;}
    .ft{margin-top:20px;text-align:right;font-size:0;}
    .title{margin-right:136px;}
    .i-letter{
      width:20px;height:20px;margin-right:8px;background-image:url(../../../assets/images/icon/letter.png);
      &.unread{background-image:url(../../../assets/images/icon/letter-active.png);}
    }
  }
  .btn-primary,
  .btn-gray{
    display:inline-block;margin-left:20px;padding:0 12px;font-size:12px;border-radius:4px;user-select:none;
    &:hover{opacity:0.8;}
  }
  .btn-primary{line-height:20px;color:#fff;background-color:#ff6f61;}
  .btn-gray{line-height:18px;color:#321908;border:1px solid #e6e6e6;background-color:#fff;}
</style>
